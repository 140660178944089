/**
 * Cargando
 * @returns {string}
 */
function cargando() {
    return "<div align=\"center\">"+
        "<img src=\"http://loadinggif.com/generated-image?imageId=11&bgColor=%23ffffff&fgColor=%23000000&transparentBg=1&download=0&random=0.11540887178853154\">"+
        "</div>";
}

/**
 * Subir imagen mediante ajax
 * @param form
 * @param urlArchivoSubida
 */
function subirAjax(form,urlArchivoSubida) {
    $('#data-up').html('');
    var a=new FormData($("#"+form)[0]);
    $.ajax({
        url:        urlArchivoSubida,
        type:       "POST",
        data:       a,
        cache:      !1,
        contentType:!1,
        processData:!1,
        beforeSend: function () {
            $("#"+form).after(''+
                '<div id="load" align="center">'+
                '<img src="http://loadinggif.com/generated-image?imageId=1&bgColor=%23ffffff&fgColor=%23000008&transparentBg=1&download=0&random=0.041722657857462764" alt="">'+
                '</div>');
        },
        success:    function (data) {
            console.log(data);
            $('#actual').remove();
            $('#load').remove();
            $('#data-up').html($.trim(data));

        },
        error:      function () {
            alert('Error ajax');
        }
    });
}

/**
 * funcion para insercion en la base de datos
 * @param archivo
 * @param tipoInsert
 * @param form
 * @param tablaRecargar
 * @param dondeRecargar
 * @param notificar
 */
function ajaxInsert(archivo,tipoInsert,form,tablaRecargar,dondeRecargar,notificar,blockPage) {
    var datosEnviados;

    //poner la notificacion por default
    if (typeof(notificar) === 'undefined') {
        notificar=true
    }
    if (blockPage == void 0) {
        blockPage=false;
    }

    //si esta nulo paso el primer form
    if (form == '' || !form) {
        //tomar los datos
        var f        =$('form');
        datosEnviados=f.first().serializeArray();
        console.log(('Formulario: '+f.first().attr('id')).trim());
    } else {
        //los dato del form
        datosEnviados=$('#'+form).serializeArray();
    }


    //pongo el resul abajo del form
    $('body').after('<div id="resul_envio"></div>');

    //si la pagina se tiene que bloquear
    if (blockPage) {
        $('body').after('<div id="block"></div>');
        var block=$('#block');

        //aplicar css a la pagina
        block.css({
            'position':        'fixed',
            'height':          '100%',
            'width':           '100%',
            'display':         'block',
            'background-color':'rgba(0,0,0,0.5)',
            'top':             '0',
            'left':            '0',
            'z-index':         '800'
        });
    }

    //lo guardo en un variable
    var resultado=$('#resul_envio');

    /**
     * aplicar css al resultado
     */
    if (notificar == true) {
        //estilo
        resultado.css({
            'font-size':        '16px',
            'padding':          '20px',
            'border':           '1px solid grey',
            'color':            '#501919',
            'background-color': 'rgba(256,256,256,1)',
            'border-radius':    '0',
            'text-transform':   'uppercase',
            'margin':           '0 auto',
            'position':         'fixed',
            'top':              '30%',
            'left':             '50%',
            'min-width':        '20%',
            'z-index':          '1999',
            'box-shadow':       '1px 1px 1px grey',
            'transform':        'translate(-50%,-50%)',
            '-webkit-transform':'translate(-50%,-50%)',
            '-moz-transform':   'translate(-50%,-50%)',
            '-ms-transform':    'translate(-50%,-50%)',
            '-o-transform':     'translate(-50%,-50%)',
            'text-align':       'center'
        });
    } else {
        resultado.css('display','none');
    }

    /**
     * El archivo que se encarga de realizar las acciones en el server
     * @type {string}
     */
    var archivoF=archivo+'.php?tipo='+tipoInsert;

    /**
     * Envio la consulta al server
     */
    $.ajax({
        beforeSend:function () {
            resultado.html(cargando());
        },
        url:       archivoF,
        type:      'POST',
        data:      datosEnviados

    }).done(function (response) {
        console.log((response).trim());
        resultado.html((response).trim());

        window.sessionStorage.lastData=response;

        //sacar el aviso
        setTimeout(function () {
            resultado.remove();
            if (blockPage) {
                block.remove();
            }
        },3500);

        //si se necesita recargar los datos en la pantalla
        if (tablaRecargar != '' || dondeRecargar != '') {
            cargarPHP(tablaRecargar,dondeRecargar)
        }

    }).fail(function (data) {
        //block.remove();
        resultado.text('Error de conexion. Vuelva a intentar'+data.responseText);
    });
}

/**
 * Ajax in con archivo predeterminado
 * @param tipoInsert
 * @param form
 * @param notificar
 * @param blockPage
 */
function ajaxIn(tipoInsert,form,notificar,blockPage) {
    var datosEnviados;

    //poner la notificacion por default
    if (typeof(notificar) === 'undefined') {
        notificar=true
    }
    if (blockPage == void 0) {
        blockPage=true;
    }

    //si esta nulo paso el primer form
    if (form == '' || !form) {
        //tomar los datos
        var f        =$('form');
        datosEnviados=f.first().serializeArray();
        console.log(('Formulario: '+f.first().attr('id')).trim());
    } else {
        //los dato del form
        datosEnviados=$('#'+form).serializeArray();
    }

    //pongo el resul abajo del form
    $('body').after('<div id="resul_envio"></div>');

    //si la pagina se tiene que bloquear
    if (blockPage) {
        $('body').after('<div id="block"></div>');
        var block=$('#block');

        //aplicar css a la pagina
        block.css({
            'position':        'fixed',
            'height':          '100%',
            'width':           '100%',
            'display':         'block',
            'background-color':'rgba(0,0,0,0.5)',
            'top':             '0',
            'left':            '0',
            'z-index':         '800'
        });
    }

    //lo guardo en un variable
    var resultado=$('#resul_envio');

    /**
     * aplicar css al resultado
     */
    if (notificar == true) {
        //estilo
        resultado.css({
            'font-size':        '16px',
            'padding':          '20px',
            'border':           '1px solid grey',
            'color':            '#501919',
            'background-color': 'rgba(256,256,256,1)',
            'border-radius':    '0',
            'text-transform':   'uppercase',
            'margin':           '0 auto',
            'position':         'fixed',
            'top':              '30%',
            'left':             '50%',
            'min-width':        '20%',
            'z-index':          '1999',
            'box-shadow':       '1px 1px 1px grey',
            'transform':        'translate(-50%,-50%)',
            '-webkit-transform':'translate(-50%,-50%)',
            '-moz-transform':   'translate(-50%,-50%)',
            '-ms-transform':    'translate(-50%,-50%)',
            '-o-transform':     'translate(-50%,-50%)',
            'text-align':       'center'
        });
    } else {
        resultado.css('display','none');
    }

    /**
     * El archivo que se encarga de realizar las acciones en el server
     * @type {string}
     */
    var archivoF='./fun/actions.php?tipo='+tipoInsert;

    /**
     * Envio la consulta al server
     */
    $.ajax({
        beforeSend:function () {
            resultado.html(cargando());
        },
        url:       archivoF,
        type:      'POST',
        data:      datosEnviados

    }).done(function (response) {
        console.log((response).trim());
        resultado.html((response).trim());
        window.sessionStorage.lastData=response;

        //sacar el aviso
        setTimeout(function () {
            resultado.remove();
            if (blockPage) {
                block.remove();
            }
        },2000);

    }).fail(function (data) {
        resultado.text('Error de conexion. Vuelva a intentar'+data.responseText);
        if (blockPage) {
            block.remove();
        }
    });
}

/**
 * Busqueda
 * @param archivo
 * @param form
 */
function busqueda(archivo,form) {
    $('#resul,#resultados,#cerrar').remove();

    //poner el resultado del ajax
    $('#'+form).after('<div id="resultados" >'+
        '<div id="resul"></div>'+
        '<a href="#!" id="cerrar" onclick="cerrate(\'#resultados,#resul,#cerrar\')" class="btn btn-block waves-block blue"><i class="fa fa-close" ></i> Cerrar</a>'+
        '</div>');
    //donde se muestran los resultados
    var resultado=$('#resul');

    //los dato del form
    var datosEnviados=$('#'+form).serializeArray();
    //el archivo que se encarga
    var archivoF     =archivo+'.php';

    $.ajax({
        beforeSend:function () {
            resultado.html(cargando());
        },

        url: archivoF,
        type:'POST',
        data:datosEnviados

    }).done(function (response) {
        console.log(response);
        resultado.html(response);

    }).fail(function (response) {
        resultado.text('Error de Ajax');
    });
}

/**
 * Cerrar
 * @param iden
 */
function cerrate(iden) {
    $(iden).remove();
}

/**
 * crear el visor de fotos
 * @param imagen
 * @param id
 */
function visor(imagen,id) {
    var visorFotos=$(''+
        '<div class="visorFotos">'+
        '<img src="img/'+id+'/BIG_'+imagen+'" alt="" onclick="cerrarVisor()" style="cursor:pointer"/>'+
        '<div>'+
        '<br/>'+
        '<button class="btn btn-block btn-primary" onclick="cerrarVisor()">Cerrar</button>'+
        '</div>'+
        '</div>');
    $('#visor').html(visorFotos);
    console.log('click');
}

/**
 * Llevar el scroll
 */
function scrollWinTop() {
    $('html,body').animate({
        scrollTop:0
    },0);
}

/**
 * expandir un div
 * @param destino
 */
function expandir(destino) {
    $(destino).toggle(function () {
    });
}

/**
 * Tomar URL completa
 * @returns {{}}
 */
function getVarsUrl() {
    var url   =location.search.replace("?","");
    var arrUrl=url.split("&");
    var urlObj={};

    for (var i=0; i<arrUrl.length; i++) {
        var x       =arrUrl[i].split("=");
        urlObj[x[0]]=x[1];
    }
    return urlObj;
}

/**
 * Centrar un div
 * @param iden
 */
function centrar(iden) {
    $(window).resize(function () {
        // aqui le pasamos la clase o id de nuestro div a centrar (en este caso "caja")
        $(iden).css({
            position:'absolute',
            left:    ($(window).width()-$(iden).outerWidth()) / 2,
            bottom:  ($(window).height()-$(iden).outerHeight()) / 2
        });
    });


    //ejecutar la funcion
    $(window).resize();

}

/**
 * Ir a una pagina
 * @param pagina
 * @returns {*}
 */
function pagina(pagina) {
    window.location.href=pagina;
}

/**
 * Crear una ventana emergente
 * @param archivo
 * @param tipo
 */
function ventanaEmergente(archivo,tipo) {
    event.preventDefault();
    //crear el visor
    var visor=''+
        '<div id="ventanaemergente">'+
        '<button class="btn btn-xs" onclick="cerrate(\'#ventanaemergente, #abajo\')">'+
        '<i class="glyphicon glyphicon-remove"></i></button>'+
        '<div id="cargate2"></div></div>';

    //crear el div de abajo
    var abajo='<div id="abajo"></div>';

    //poner la ventana y el fondo negro
    $('body').append(visor);
    $('body').prepend(abajo);

    $('#abajo').css({
        'background':'rgba(0,0,0,.9)',
        'width':     '100%',
        'height':    '100% !important',
        'top':       0,
        'left':      0,
        'right':     0,
        'bottom':    0,
        'margin':    '0 auto',
        'z-index':   500,
        'position':  'fixed'
    });

    $('#ventanaemergente').css({
        'width':            '70%',
        'height':           'auto',
        'padding':          '10px',
        'position':         'fixed',
        'top':              '50%',
        'transform':        'translate(-50%,-50%)',
        '-webkit-transform':'translate(-50%,-50%)',
        '-moz-transform':   'translate(-50%,-50%)',
        '-ms-transform':    'translate(-50%,-50%)',
        '-o-transform':     'translate(-50%,-50%)',
        'left':             '50%',
        'background-color': 'white',
        'z-index':          '1100',
        'box-shadow':       '0 0 3px 1px black',
        'border-radius':    '4px'
    });

    //cargar el contenido
    $('#cargate2').load(archivo+'?tipo='+tipo);
}

/**
 * funcion para carga de php
 * @param archivo
 * @param donde
 */
function cargarPHP(archivo,donde) {
    $(donde).before('<div id="resultadoLoad"></div>');
    var load=$("#resultadoLoad");

    //Load CSS
    load.css({
        'padding':          '20px',
        'color':            '#501919',
        'background':       'transparent',
        'border-radius':    '2px',
        'margin':           '0 auto',
        'position':         'fixed',
        'top':              '50%',
        'left':             '50%',
        'min-width':        '35%',
        'z-index':          '1999',
        //'box-shadow': '0 0 2px 1px grey',
        'transform':        'translate(-50%,-50%)',
        '-webkit-transform':'translate(-50%,-50%)',
        '-moz-transform':   'translate(-50%,-50%)',
        '-ms-transform':    'translate(-50%,-50%)',
        '-o-transform':     'translate(-50%,-50%)',
        'text-align':       'center'
    });


    $.ajax({
        type:      "GET",
        url:       archivo,
        data:      '',
        dataType:  "html",
        beforeSend:function () {
            //imagen de carga
            load.html(cargando());
        },
        error:     function () {
            console.log("error peticion ajax");
        },
        success:   function (data) {
            $(donde).html(data);
            load.remove();

        }
    });
}

/**
 * Cambiar pantalla
 * @param pantalla
 * @param tiempo
 */
function cambiarPantalla(pantalla,tiempo) {
    setTimeout(function () {
        window.location.href='index.php?sec='+pantalla;
    },tiempo);
}

/**
 * Sumar fechas
 * @param d
 * @param fecha
 * @returns {string}
 */
sumaFecha=function (d,fecha) {
    var Fecha =new Date();
    var sFecha=fecha || (Fecha.getDate()+"-"+(Fecha.getMonth()+1)+"-"+Fecha.getFullYear());
    var sep   =sFecha.indexOf('-') != -1 ? '-' : '-';
    var aFecha=sFecha.split(sep);
    var fecha =aFecha[2]+'-'+aFecha[1]+'-'+aFecha[0];
    fecha     =new Date(fecha);
    fecha.setDate(fecha.getDate()+parseInt(d));
    var anno      =fecha.getFullYear();
    var mes       =fecha.getMonth()+1;
    var dia       =fecha.getDate();
    mes           =(mes<10) ? ("0"+mes) : mes;
    dia           =(dia<10) ? ("0"+dia) : dia;
    var fechaFinal=dia+sep+mes+sep+anno;
    return (fechaFinal);
};

/**
 * Filtro de estados
 * @param estado
 */
function filtroEstado(estado) {
    if (estado === '') {
        $('.abierto, .cerrado').css({
            'display':'block'
        });
    } else {
        $('.collapsible li:not(.'+estado+')').css({
            'display':'none'
        });
        $('.'+estado).css({
            'display':'block'
        });
    }
}

/**
 * Buscar en la tabla
 */
var searchOnTable=function () {
    var table=$('table');
    var value=this.value;
    table.find('tr').each(function (index,row) {
        var allCells=$(row).find('td');
        if (allCells.length>0) {
            var found=false;
            allCells.each(function (index,td) {
                var regExp=new RegExp(value,'i');
                if (regExp.test($(td).text())) {
                    found=true;
                    return false;
                }
            });
            if (found == true) $(row).show();
            else $(row).hide();
        }
    });
};

/**
 * Enviar notificaciones HTML5
 * @type {{Soporte: boolean, Permisos: Function, Enviar: Function}}
 */
var NOTIFY={
    Soporte: false,
    /**
     * Pedir permisos
     * @constructor
     */
    Permisos:function () {
        if (window.Notification) {
            //ver si tiene soporte el navegador
            NOTIFY.Soporte=true;
            //pedir permiso
            if (Notification.permission !== "granted") {
                Notification.requestPermission();
            }
        } else {
            NOTIFY.Soporte=false;
        }
    },
    /**
     * Enviar notificacion al sistema
     * @param titulo
     * @param body
     * @constructor
     */
    Enviar:  function (titulo,body) {

        icono='./img/icon.png';

        if (NOTIFY.Soporte === true) {
            var title=titulo;
            var extra={
                icon:icono,
                body:body
            };

            var noti    =new Notification(title,extra)
            noti.onclick={
                // Al hacer click
            };
            noti.onclose={
                // Al cerrar
            };

            //cerrar
            setTimeout(function () {
                noti.close()
            },10000)
        }
    }
};

/**
 * Visor de fotos en pantalla
 * @type {{open: Function, close: Function}}
 */
var LoadImage={
    /**
     * Abrir la imagen
     * @param imagen
     */
    open:function (imagen) {
        //crear el visor
        $('body').append('<div id="loadImage" ><img src="'+imagen+'" alt="imagen"></div>')
        $('body').append('<div id="cerrarFondo" onclick="LoadImage.close()"></div>')
        $('body').append('<div id="cerrarImage"><button class="btn btn-flat white-text" onclick="LoadImage.close()">CERRAR [X]</button></div>');

        //aplicar CSS
        $('#loadImage').css({
            'position':  'fixed',
            'left':      '50%',
            'top':       '50%',
            'transform': 'translate(-50%, -50%)',
            'z-index':   '999',
            'padding':   '.3em',
            'box-shadow':'0 0 2px 1px grey',
            'background':'white'
        });
        $('#loadImage > img').css({
            'max-height':'700px',
            'width':     '100%'
        });
        $('#cerrarFondo').css({
            'position':  'fixed',
            'width':     '100%',
            'height':    '100%',
            'z-index':   '998',
            'top':       '0',
            'left':      '0',
            'background':'rgba(0, 0, 0, .8)'
        });
        $('#cerrarImage').css({
            'position':'fixed',
            'top':     '2%',
            'z-index': '998',
            'right':   '2%'
        });
        $('#cerrarImage > a').css({
            'color':'white'
        });

    },

    /**
     * Cerrar el visor
     */
    close:function () {
        $('#loadImage,#cerrarFondo,#cerrarImage').remove();
    }
};

var marker;
/**
 * Class para los manejos de los mapas
 * @type {{Init: MAPA.Init, BuscarDireccion: MAPA.BuscarDireccion, BuscarEnMapa: MAPA.BuscarEnMapa, VerClientesMapa: MAPA.VerClientesMapa}}
 */
var MAPA={
    /**
     * Iniciar un mapa
     * @param mapaDiv
     * @param latitud
     * @param longitud
     * @returns {*}
     * @constructor
     */
    Init:function (mapaDiv,latitud,longitud) {
        // set up the map
        if (mapaDiv === void 0) {
            mapaDiv='mapa';
        }
        if (latitud === void 0) {
            latitud= -38.72126349422913;
        }
        if (longitud === void 0) {
            longitud= -62.25637244999996;
        }
        var mapaUrlOnline='http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
        map              =new L.Map(mapaDiv,{zoomControl:false});

        //atributos del mapa
        var osm=new L.TileLayer(
            mapaUrlOnline,
            {
                minZoom:             3,
                //maxZoom: 21,
                attribution:         '',
                reuseTiles:          true,
                errorTileUrl:        'error',
                continuousWorld:     false,
                detectRetina:        false,
                unloadInvisibleTiles:false
            }
        );

        // setear las cordenadas en tandil
        map.setView(new L.LatLng(latitud,longitud),14);
        map.addLayer(osm);

        //zoom a la izquierda
        //new L.Control.Zoom({position: 'topright'}).addTo(map);
        return map;
    },

    /**
     * Buscar una direccion
     * @param mapaDiv
     * @param latitud
     * @param longitud
     * @constructor
     */
    BuscarDireccion:function (mapaDiv,latitud,longitud) {
        if (mapaDiv === void 0) {
            mapaDiv='mapa';
        }

        console.log('Datos mapa');
        var direccion           =$('#direccion').val()+', '+$('#ciudad').val();
        var url                 ='http://nominatim.openstreetmap.org/search?q='+direccion+'&format=json&addressdetails=1';
        L.Icon.Default.imagePath='./img';

        $.getJSON(url,function (data) {
            var e=eval(data);
            console.log(e);
            var lat=e[0].lat;
            var lon=e[0].lon;
            if (lat != '' && lon != '') {
                $('#latitud').val(e[0].lat);
                $('#longitud').val(e[0].lon);

                if (marker != undefined) {
                    map.removeLayer(marker);
                }
                setTimeout(function () {
                    map.setView(new L.LatLng(lat,lon),18);
                    marker=L.marker([lat,lon]).addTo(map);
                },1000)
            } else {
                alert('Direccion no encontrada')
            }

        });

    },

    /**
     * Marcar en el mapa
     * @param lat
     * @param lon
     * @param contenido
     * @constructor
     */
    MarcarEnMapa:   function (lat,lon,contenido) {
        L.Icon.Default.imagePath='./img';
        map.setView(new L.LatLng(lat,lon),18);
        if (marker != undefined) {
            map.removeLayer(marker);
        }
        marker=L.marker([lat,lon]).addTo(map);
        marker.bindPopup(contenido).openPopup();
    },
    /**
     * Ver clientes en el mapa
     * @constructor
     */
    VerClientesMapa:function () {
        if (marker != undefined) {
            map.removeLayer(marker);
        }

        $.ajax({
            method:    'get',
            url:       './DATA/data.php?t=allClientes',
            data:      {},
            beforeSend:function () {
            },
            success:   function (data) {
                var e=eval(data);
                for (var i=0; i<e.length; i++) {
                    L.Icon.Default.imagePath='./img';
                    console.log(data);
                    map.setZoom(12);
                    marker       =L.marker([e[i].latitud,e[i].longitud]).addTo(map);
                    var contenido=e[i].nombre+' '+e[i].apellido+'<br>'+e[i].direccion;
                    marker.bindPopup(contenido);
                }

            },
            error:     function () {

            }
        })
    }
};

/**
 * Selects dinamicos
 * @type {{GetData: SELECT.GetData, CreateSelect: SELECT.CreateSelect}}
 */
var SELECT={
    GetData:     function (url) {
        return $.getJSON(url);
    },
    CreateSelect:function (urlData,nameSelect,divContainer) {
        var divSelect=$(divContainer);

        $.getJSON(urlData,function (data) {
            var e     =eval(data);
            var render='';
            render+='<select name="'+nameSelect+'" id="'+nameSelect+'" >'
        });
    }
};

/**
 * Sucursales
 * @type {{getSucursales: Sucursales.getSucursales, getConsultorio: Sucursales.getConsultorio}}
 */
var Sucursales={
    /**
     * Get sucursales
     * @param destino
     */
    getSucursales:function (destino) {
        $.getJSON('fun/data.php?tipo=sucursales',function (res) {
            console.log(res);
            var e=eval(res);
            var p='';
            p+='<label>Sucursal</label>';
            p+='<select name="sucursales" class="form-control" id="sucursales">';
            for (var i=0; i<e.length; i++) {
                p+='<option value="'+e[i].id+'">'+e[i].nombre+'</option>';
            }
            p+='</select>';
            p+='<div id="cons"></div>';

            if ($(destino).html(p)) {
                /*setTimeout(function () {
                 Sucursales.getConsultorio($('#sucursales').val(), '' +
                 '');
                 }, 1500)*/
            }
        }).error(function (res) {
            console.log('Error al obtener las sucursales')
        })
    },

    /**
     * Get consultorios
     * @param sucursal
     * @param destino
     */
    getConsultorio:function (sucursal,destino) {
        $.post('fun/data.php?tipo=consultorios&sucursal='+sucursal,function (res) {
            console.log(res);
            var e  =eval(res);
            var con='';

            con+='<label>Consultorio</label>';
            con+='<select name="consultorios" class="form-control" id="consultorios">';
            //con+='<option value="" selected disabled>Seleccionar consultorio...</option>'
            for (var i=0; i<e.length; i++) {
                con+='<option value="'+e[i].id+'">'+e[i].nombre+'</option>';
            }
            con+='</select>';

            $(destino).html(con)

        }).error(function (res) {
            console.log('Error al obtener los consultorios')
        })
    },

    /**
     * get pacientes de la sucursal
     * @param suc
     */
    getPacientesSucursal:function (suc) {
        console.log('pacientes de sucursal');
        $.getJSON('fun/data.php?tipo=pacientesSucursal&sucursal='+suc,function (res) {
            var e=eval(res);
            console.log('-------- pacientes ---->');
            console.log(e);
            var con='';

            con+='<label>Paciente</label>';
            con+='<select name="paciente" class="form-control" id="paciente" data-live-search="true">';
            for (var i=0; i<e.length; i++) {
                con+='<option value="'+e[i].id+'">'+e[i].Nombre+'</option>';
            }
            con+='</select>';

            if ($('#pac').html(con)) {
                Paciente.zonasDelPaciente($('#paciente').val());
            }

            //selectpicker
            setTimeout(function () {
                $('#paciente').selectpicker('refresh');
            },500);

        }).error(function (err) {
            console.log(err.responseText)
        })
    },

    getPacientesSucursalTurno:function (suc) {
        console.log('pacientes de sucursal'+suc);
        $.post('fun/data.php?tipo=pacientesSucursalTurno&sucursal='+suc,function (res) {
            var e=eval(res);
            console.log('-------- pacientes ---->');
            var con='';

            con+='<label>Paciente*</label>';
            con+='<select name="paciente" class="form-control" id="paciente" data-live-search="true">';
            con+='<option value="0" selected>Seleccionar paciente</option>';
            for (var i=0; i<e.length; i++) {
                con+='<option value="'+e[i].id+'">'+e[i].Nombre+'</option>';
            }
            con+='</select>';

            //if ($('#pac').html(con)) {
            //    Paciente.zonasDelPaciente($('#paciente').val());
            //}

            //selectpicker
            setTimeout(function () {
                //$('#paciente').selectpicker('refresh');
            },500);

        }).error(function (err) {
            console.log(err.responseText)
        })
    },
    /**
     * Insertar sucursal
     */
    insertSucursal:           function () {
        ajaxInsert('fun/actions','insertSucursal','addConsul','sec/listarSucursales.php','.data');

        setTimeout(function () {
            Sucursales.getSucursales('#sucs');
        },1000)
    },

    /**
     * insertar un consultorio
     */
    insertConsultorio:function () {
        ajaxInsert('fun/actions','insertConsultorio','addSuc','sec/listarSucursales.php','.data');
    },

    deleteSucursal:function (id) {
        if (confirm('¿Desea borrar la sucursal?')) {
            ajaxInsert('fun/actions','deleteSucursal&id='+id,'','sec/listarSucursales.php','.data');
        }
    },

    deleteConsultorio:function (id) {
        if (confirm('¿Desea borrar el consultorio?')) {
            ajaxInsert('fun/actions','deleteConsultorio&id='+id,'','sec/listarSucursales.php','.data');
        }
    }
};

/**
 * Zonas de tratamiento
 * @type {{get: Zonas.get}}
 */
var Zonas={
    /**
     * Get zonas
     * @param sexo
     * @param destinoDiv
     */
    get:function (sexo,destinoDiv) {
        $.getJSON('fun/data.php?tipo=zonas&sexo='+sexo,function (res) {
            var e=eval(res);
            console.log(e);

            var render='';
            render+='<label for="zona">Zona</label>';
            render+='<select name="zona" id="zona" class="form-control">';
            render+='<option value="0">Seleccionar zona</option>';
            for (var i=0; i<e.length; i++) {
                var nombre=e[i].nombre;
                var sex   =e[i].sexo;
                var valor =' $'+e[i].valor;
                var mins  =e[i].minutos+'Mins';
                var id    =e[i].id;
                render+='<option value="'+id+'">'+nombre+' | '+valor+' - '+mins+'</option>';
            }
            render+='</select>';

            $(destinoDiv).html(render);

        }).error(function (res) {
            console.log(res.responseText);
        })
    },

    /**
     * Borrar una zona
     * @param id
     */
    delete:function (id) {
        if (confirm('¿Desea borrar?')) {
            ajaxInsert('fun/actions','deleteZona&id='+id,'a','sec/listarZonas.php','.data');
        }
    },

    /**
     * Editar zona
     */
    editZona:function () {
        ajaxInsert('fun/actions','editZona','edit')
    }
};

/**
 * Ver los turnos
 * @type {{verTurnos: Turnos.verTurnos, crear: Turnos.crear}}
 */
var Turnos={
    /**
     * Ver los turnos
     * @returns {boolean}
     */
    verTurnos:function () {
        var data={
            paciente:        $('#paciente').val(),
            sucursal:        $('#sucursal').val(),
            consultorio:     $('#consultorios').val(),
            fecha:           $('#fecha').val(),
            tiempo_de_sesion:$('#tiempo_de_sesion').val(),
            zona:            $('#zona').val()
        };
        console.log(data);

        if (data.fecha == '') {
            $('.data').html('<h3 align="center">Seleccionar una fecha</h3>');
            return false;
        }

        var url='sec/verTurnos.php?paciente='+data.paciente+'&fecha='+data.fecha+'&tiempo_de_sesion='+data.tiempo_de_sesion+'&sucursal='+data.sucursal+'&consultorio='+data.consultorio+'&zona='+data.zona;
        cargarPHP(url,'.data');
    },

    /**
     * Crear un turno
     */
    crear:function (hora) {
        $('#crear').modal('show');
        var paciente    =$('#paciente').val();
        var zonaPaciente=$('#zona').val();

        $.getJSON('./fun/data.php?tipo=paciente&paciente='+paciente+'&zona='+zonaPaciente,function (res) {
            var e=eval(res[0]);
            console.log(e);

            $('#id').val(e.id);
            $('#name').html(e.nombre+' '+e.apellido);
            $('#suc').html(e.Sucursal);
            $('#sucursales').val(e.sucursal);
            $('#sesion_time').html(e.zona_minutos+' Minutos');
            $('#minutos').val(e.zona_minutos);
            $('#fecha_turno').html($('#fecha').val().split('-').reverse().join('-'));
            $('#hora_turno').html(hora);
            $('#hora').val(hora);
            $('#zonasDiv').html(e.zona_nombre+' $'+e.zona_valor);
            $('#zona_a_tratar').val($('#zona').val());

            Sucursales.getConsultorio(e.sucursal,'#sucs');

        }).error(function (err) {
            console.log('Error al cargar datos del paciente'+err.responseText);
        })
    },

    /**
     * Crear un sobre turno
     * @param form
     */
    crearSobre:function (form) {
        console.log($('#fecha_turno').val());
        ajaxInsert('fun/actions','createSobreTurno',form);
        Turnos.cargaTurnosFecha();
        $('#st').modal('hide');
        $('#st :text,#st textarea').val('');
    },

    /**
     * Mostrar modal de sobre turno
     * @param turno
     * @param hora
     * @param fecha
     */
    modalSobre:function (turno,hora,fecha) {
        $('#turno').val(turno);
        $('#hora_inicio').val(hora);
        $('#fecha_turno').val(fecha);
        $('#sobreT').modal('show');


    },

    /**
     * Ver turnos para el paciente
     */
    verTurnosPaciente:function () {
        console.log('cargar');

        var data={
            fecha:      $('#fecha').val(),
            consultorio:$('#consultorio').val(),
            paciente:   window.sessionStorage.id,
            sucursal:   window.sessionStorage.sucursal,
            tiempo:     window.sessionStorage.tiempo,
            zona:       $('#zona').val()
        };

        //url
        var url='fun/verTurnosPaciente.php'+
            '?paciente='+data.paciente+''+
            '&tiempo_de_sesion='+data.tiempo+''+
            '&sucursal='+data.sucursal+''+
            '&fecha='+data.fecha+''+
            '&zona='+data.zona+''+
            '&consultorio='+data.consultorio;

        $('.load').load(url,function () {
            console.log('Cargado ok');
        });
    },

    verTurnosPacienteAll:function (paciente) {
        $.ajax({
            url:    'fun/data.php',
            method: 'POST',
            data:   {
                paciente:window.sessionStorage.id,
                tipo:    'turnosAll',
                t:       'turnosAll'
            },
            success:function (res) {
                var e=eval(res);
                console.log(e);
                var rend='';

                var Dia      =new Date();
                var diaActual=Dia.getFullYear()+'-'+Dia.getMonth()+'-'+Dia.getDate();

                var consul,fecha,zona,diff,disabled;

                for (var i=0; i<e.length; i++) {
                    consul=e[i].Consultorio != null ? e[i].Consultorio : '';

                    diff    =e[i].Diferencia;
                    disabled=diff<=2 ? 'disabled' : '';

                    rend+=''+
                        '<tr>'+
                        '<td>'+e[i].Fecha+' '+e[i].hora+'</td>'+
                        '<td>'+consul+'</td>'+
                        '<td>'+e[i].Zona+'</td>'+
                        '<td>'+
                        '<button '+disabled+' onclick="Turnos.cancelarTurnoPaciente(\''+e[i].id+'\',\'cancelado\')" class="btn btn-danger btn-sm">Cancelar</button>'+
                        '</td>'+
                        '</tr>';
                }

                $('#mis').html(rend);
            },
            error:  function (res) {

            }
        })
    },

    /**
     * Crear paciente
     * @param hora
     * @returns {boolean}
     */
    crearPaciente:function (hora) {
        $('#crear-button').attr('disabled',true);

        var data  ={
            sucursal:   window.sessionStorage.sucursal,
            paciente:   window.sessionStorage.id,
            fecha:      $('#fecha').val(),
            tratamiento:0,
            tiempo:     window.sessionStorage.tiempo,
            consultorio:$('#consultorios').val(),
            zona:       window.sessionStorage.zona,
            hora:       hora,
            minutos:    window.sessionStorage.tiempo,
            tipo:       'createTurno',
            notas:      'Creado desde seccion pacientes'
        };
        var fecha =$('#fecha').val();
        var fechaF=fecha.split('-').reverse().join('-');


        if (confirm('Se creara el turno en la fecha '+fechaF+'a las '+hora+' ¿Desea continuar?')) {
            console.log(data);
            var respuesta=$('#res');
            $.ajax({
                url:       'fun/actions.php',
                method:    'POST',
                data:      data,
                beforeSend:function () {
                    respuesta.html('Creando turno, espere...');
                },
                success:   function (res) {
                    console.log(res);

                    respuesta.html(res);

                    //recargar los turnos
                    Turnos.verTurnos();

                    setTimeout(function () {
                        $('textarea').val('');
                        respuesta.html('');
                        $('#crear').modal('hide');
                        $('#crear-button').attr('disabled',false);
                    },1000)
                },
                error:     function (res) {
                    console.log('error');
                    console.log(res.responseText);
                }
            });
        } else {
            return false;
        }
    },

    /**
     * Crear un turnos desde la seccion turnos
     */
    crearTurnoAdmin:function () {
        $('#crear-button').attr('disabled',true);
        var data={
            sucursal:   $('#sucursal').val(),
            paciente:   $('#paciente').val(),
            fecha:      $('#fecha').val(),
            tratamiento:$('#tratamiento').val(),
            tiempo:     $('#tiempo_de_sesion').val(),
            consultorio:$('#consultorios').val(),
            zona:       $('#zona_a_tratar').val(),
            hora:       $('#hora').val(),
            minutos:    $('#minutos').val(),
            tipo:       'createTurno',
            notas:      $('#notas').val()
        };

        console.log(data);
        var respuesta=$('#res');

        $.ajax({
            url:       'fun/actions.php',
            method:    'POST',
            data:      data,
            beforeSend:function () {
                respuesta.html('Creando turno, espere...');
            },
            success:   function (res) {
                console.log(res);

                respuesta.html(res);

                //recargar los turnos
                Turnos.verTurnos();

                setTimeout(function () {
                    $('textarea').val('');
                    respuesta.html('');
                    $('#crear').modal('hide');
                    $('#crear-button').attr('disabled',false);
                },1000)
            },
            error:     function (res) {
                console.log('error');
                console.log(res.responseText);
            }
        })
    },

    /**
     * Crear un turnos desde la seccion pacientes
     * para el paciente
     */
    crearTurnoPaciente:function (hora) {
        var data={
            sucursal:      window.sessionStorage.sucursal,
            paciente:      window.sessionStorage.id,
            fecha:         $('#fecha').val(),
            tratamiento:   0,
            tiempo:        window.sessionStorage.tiempo,
            consultorio:   $('#consultorio').val(),
            zona:          $('#zona').val(),
            hora:          hora,
            minutos:       window.sessionStorage.tiempo,
            tipo:          'createTurno',
            notas:         'Creado desde seccion pacientes',
            desde_paciente:1
        };

        var fecha =data.fecha;
        var fechaF=fecha.split('-').reverse().join('-');

        if (confirm('Se creara el turno en la fecha '+fechaF+' a las '+hora+' ¿Desea continuar?')) {
            console.log(data);
            var respuesta=$('#res');
            $.ajax({
                url:       'adm1986/fun/actions.php',
                method:    'POST',
                data:      data,
                beforeSend:function () {
                    respuesta.html('Creando turno, espere...');
                },
                success:   function (res) {
                    console.log(res);

                    respuesta.html(res);

                    //recargar los turnos
                    Turnos.verTurnosPaciente();

                    setTimeout(function () {
                        $('textarea').val('');
                        respuesta.html('');
                        $('#crear').modal('hide');
                        $('#crear-button').attr('disabled',false);
                    },1000)
                },
                error:     function (res) {
                    console.log('error');
                    console.log(res.responseText);
                }
            });
        } else {
            return false;
        }
    },

    /**
     * Cargar los turnos por fecha
     */
    cargaTurnosFecha:function () {
        var fecha =$('.well #fecha');
        var suc   =$('.well #sucursales');
        var consul=$('.well #consultorios');

        if (fecha.val()) {
            var url='sec/listarTurnosDia.php?fecha='+fecha.val()+'&suc='+suc.val()+'&consul='+consul.val();
            console.log(url);
            cargarPHP(url,'.data');
        } else {
            alert('Seleccione la fecha');
        }
    },

    /**
     * Marcar atendido o cancelado
     * @param id
     * @param estado
     * @param sobre
     * @param recordatorio
     */
    atendido:function (id,estado,sobre,recordatorio) {
        $('#atendido').modal('show');
        $('#idTurno').val(id);
        $('#estadoTurno').val(estado);
        $('#sobre').val(sobre);

        if (recordatorio == 0) {
            $('#proxima').attr('disabled',true);
        } else {
            $('#proxima').attr('disabled',false);
        }
    },

    /**
     * Marcar turno
     * @param id
     * @param estado
     */
    marcarTurno:function (id,estado) {
        var data={
            id:        id,
            comentario:$('#comentario').val(),
            tipo:      'estadoTurno',
            estado:    estado
        };

        $.ajax({
            url:       'fun/actions.php',
            data:      data,
            method:    'POST',
            beforeSend:function () {

            },
            success:   function (res) {
                console.log(res);
            },
            error:     function (res) {
                console.log('Error')
                console.log(res)
            }
        })
    },

    /**
     * Marcar turno
     * @param id
     * @param estado
     */
    cancelarTurnoPaciente:function (id,estado) {
        var data={
            idTurno:    id,
            comentario: $('#comentario').val(),
            tipo:       'cancelarTurnoPaciente',
            estadoTurno:estado,
            proxima:    null
        };
        $.ajax({
            url:       'adm1986/fun/actions.php',
            data:      data,
            method:    'POST',
            beforeSend:function () {

            },
            success:   function (res) {
                console.log(res);
                alert(res);
                Turnos.verTurnosPacienteAll();
            },
            error:     function (res) {
                console.log('Error');
                console.log(res)
            }
        })
    },

    /**
     * Cambio de estado
     */
    cambioEstado:function () {
        ajaxInsert('fun/actions','cambioEstado','comen','','',true,true);
        $('textarea').val('');
        $('#atendido').find('input[type=date]').val('');
        $('#atendido').modal('hide');
        Turnos.cargaTurnosFecha();
        Turnos.cargaTurnosFecha();
    }
};

var SobreTurno={};
/**
 * Paciente data
 * @type {{deletePaciente: Paciente.deletePaciente}}
 */
var Paciente  ={
    /**
     *
     * @param archivo
     * @param tipoInsert
     * @param form
     * @param tablaRecargar
     * @param dondeRecargar
     * @param notificar
     * @param blockPage
     */
    insertPaciente:function (archivo,tipoInsert,form,tablaRecargar,dondeRecargar,notificar,blockPage) {
        var datosEnviados;

        //poner la notificacion por default
        if (typeof(notificar) === 'undefined') {
            notificar=true
        }
        if (blockPage == void 0) {
            blockPage=false;
        }

        //si esta nulo paso el primer form
        if (form == '' || !form) {
            //tomar los datos
            var f        =$('form');
            datosEnviados=f.first().serializeArray();
            console.log(('Formulario: '+f.first().attr('id')).trim());
        } else {
            //los dato del form
            datosEnviados=$('#'+form).serializeArray();
        }

        //pongo el resul abajo del form
        $('body').after('<div id="resul_envio"></div>');

        //si la pagina se tiene que bloquear
        if (blockPage) {
            $('body').after('<div id="block"></div>');
            var block=$('#block');

            //aplicar css a la pagina
            block.css({
                'position':        'fixed',
                'height':          '100%',
                'width':           '100%',
                'display':         'block',
                'background-color':'rgba(0,0,0,0.5)',
                'top':             '0',
                'left':            '0',
                'z-index':         '800'
            });
        }

        //lo guardo en un variable
        var resultado=$('#resul_envio');

        /**
         * aplicar css al resultado
         */
        if (notificar == true) {
            //estilo
            resultado.css({
                'font-size':        '16px',
                'padding':          '20px',
                'border':           '1px solid grey',
                'color':            '#501919',
                'background-color': 'rgba(256,256,256,1)',
                'border-radius':    '0',
                'text-transform':   'uppercase',
                'margin':           '0 auto',
                'position':         'fixed',
                'top':              '30%',
                'left':             '50%',
                'min-width':        '20%',
                'z-index':          '1999',
                'box-shadow':       '1px 1px 1px grey',
                'transform':        'translate(-50%,-50%)',
                '-webkit-transform':'translate(-50%,-50%)',
                '-moz-transform':   'translate(-50%,-50%)',
                '-ms-transform':    'translate(-50%,-50%)',
                '-o-transform':     'translate(-50%,-50%)',
                'text-align':       'center'
            });
        } else {
            resultado.css('display','none');
        }

        /**
         * El archivo que se encarga de realizar las acciones en el server
         * @type {string}
         */
        var archivoF=archivo+'.php?tipo='+tipoInsert;

        /**
         * Envio la consulta al server
         */
        $.ajax({
            beforeSend:function () {
                resultado.html(cargando());
            },
            url:       archivoF,
            type:      'POST',
            data:      datosEnviados

        }).done(function (response) {
            console.log((response).trim());
            resultado.html((response).trim());
            window.sessionStorage.lastData=response;

            if ($.trim(response) == 'paciente ingresado') {

            }

            //sacar el aviso
            setTimeout(function () {
                resultado.remove();
                if (blockPage) {
                    block.remove();
                }
            },2000);

            //si se necesita recargar los datos en la pantalla
            if (tablaRecargar != '' || dondeRecargar != '') {
                cargarPHP(tablaRecargar,dondeRecargar)
            }

        }).fail(function (data) {
            block.remove();
            resultado.text('Error de conexion. Vuelva a intentar'+data.responseText);
        });
    },


    /**
     * Borrar paciente
     * @param id
     * @returns {boolean}
     */
    deletePaciente:function (id) {
        if (!confirm('Desea borrar el paciente')) {
            return false;
        } else {
            ajaxInsert('fun/actions','deletePaciente&id='+id,'','sec/listarPacientes.php','.data')
        }
    },

    /**
     * Data del paciente
     * @param idPaciente
     * @param zona
     */
    getDataPaciente:function (idPaciente,zona) {
        var url='fun/data.php?tipo=pacienteData&id='+idPaciente+'&zona='+zona;
        console.log(url);

        $.getJSON(url,function (res) {
            var e=eval(res[0]);
            console.log(e);
            var tiempo_de_sesion=e.tiempo_de_sesion;

            $('#tiempo_de_sesion').val(tiempo_de_sesion);
        })
    },

    /**
     * Editar un paciente
     */
    editPaciente:function () {
        ajaxInsert('fun/actions','editPaciente','add','sec/listarPacientes.php','.data');
        setTimeout(function () {
            window.location.href='?sec=pacientes';
        },1000)
    },

    /**
     * Editar desde la seccion turno
     * @param id
     */
    editPacienteTurnos:function (id) {
        $('#editData').modal('show');

        $.getJSON('fun/data.php?tipo=pacienteData&id='+id+'&zona=0',function (res) {
            console.log('Data paciente');

            var e=eval(res[0]);
            console.log(e);
            $('#editP').find('#nombre').val(e.nombre);
            $('#editP').find('#apellido').val(e.apellido);
            $('#editP').find('#mail').val(e.email);
            $('#editP').find('#telefono').val(e.telefono_fijo);
            $('#editP').find('#movil').val(e.telefono_movil);
            $('#editP').find('#id').val(e.id);
            $('#editP').find('#direccion').val(e.direccion);
            $('#editP').find('#localidad').val(e.localidad);
            $('#editP').find('#codigo_postal').val(e.cp);
            $('#editP').find('#facebook').val(e.facebook);
            $('#editP').find('#dni').val(e.dni);
            $('#editP').find('#ocupacion').val(e.ocupacion);
            $('#editP').find('#observaciones').val(e.observaciones);
            $('#editP').find('#fecha_nacimiento').val(e.fecha_nacimiento);
        })
    },

    /**
     * Editar desde la seccion consulta de turnos
     */
    editPacienteFromTurnos:function () {
        ajaxInsert('fun/actions','editPacienteFromTurnos','editP');

        setTimeout(function () {
            $('#editData').modal('close')
        },2000)
    },

    /**
     * Get historia clinica from turnos
     * @param id
     */
    getHistoriaClinica:function (id) {
        $('#historia').modal('show');

        var url='fun/data.php?tipo=historiaClinica&id='+id;
        $.getJSON(url,function (res) {
            console.log(res);
            var e=eval(res);
            var rend='';
            for (var i=0; i<e.length; i++) {
                rend+='<tr>';
                rend+='<td>'+e[i].Fecha+'</td>';
                rend+='<td>'+e[i].comentarios+'</td>';
                rend+='<td>'+e[i].Atendido_por+'</td>';
                rend+='<td>'+e[i].Zona+'</td>';
                rend+='<td>'+e[i].Estado+'</td>';
                rend+='</tr>';
            }

            $('#listar').html('').html(rend);
            $('#obser').html('').html(e[0].Observ);
        });

        var url='fun/data.php?tipo=historiaClinicaCancel&id='+id;
        $.getJSON(url,function (res) {
            console.log(res);
            var e=eval(res);

            var rend='';
            for (var i=0; i<e.length; i++) {
                rend+='<tr>';
                rend+='<td>'+e[i].Fecha+'</td>';
                rend+='<td>'+e[i].comentarios+'</td>';
                rend+='<td>'+e[i].Atendido_por+'</td>';
                rend+='<td>'+e[i].Zona+'</td>';
                rend+='<td>'+e[i].Estado+'</td>';
                rend+='</tr>';
            }

            $('#listar2').html('').html(rend);
        });

        var url='fun/data.php?tipo=historiaClinicaSobreTurnos&id='+id;

        $.getJSON(url,function (res) {
            console.log(res);
            var e=eval(res);

            var rend='';
            for (var i=0; i<e.length; i++) {
              
                if(e[i].Estado==='Atendido'){
                    var color='green'
                }else{
                    var color='red'
                }

                rend+='<tr style="color:'+color+'">';
                rend+='<td>'+e[i].Fecha+'</td>';
                rend+='<td>'+e[i].comentarios+'</td>';
                rend+='<td>'+e[i].Atendido_por+'</td>';
                rend+='<td>'+e[i].Zona+'</td>';
                rend+='<td>'+e[i].Estado+'</td>';
                rend+='</tr>';
            }

            $('#listar3').html('').html(rend);
        });


    },

    /**
     * Turnos del paciente
     */
    getTurnosPaciente:function () {
        var idPaciente=$('#paciente').val();

        $.ajax({
            url:    'fun/data.php',
            method: 'GET',
            data:   {
                tipo:'turnosPaciente',
                id:  idPaciente
            },
            success:function (res) {
                console.log(res);
                var e=eval(res);

                var turnosPac=$('#turnosPaciente');
                turnosPac.html('');
                var rend='<label for="turno_paciente">Turno</label>'+
                    '<select name="turno_paciente" id="turno_paciente" class="form-control">';

                for (var i=0; i<e.length; i++) {
                    rend+='<option value="'+e[i].id+'">Turno: '+e[i].Data+'Hs</option>';
                }
                // si no tiene
                if (e.length == 0) {
                    rend+='<option value="0">Paciente sin turnos</option>';
                }
                rend+='</select>';
                turnosPac.html(rend);
            },
            error:  function (res) {
                console.log(res.responseText);
            }
        })
    },

    /**
     * Zonas del paciente
     * @param paciente
     */
    zonasDelPaciente:  function (paciente) {

        /*$.ajax({
          url:'fun/data.php',
          method:'POST',
          async:false,
          timeout:3000,
          data:{
            idpaciente:paciente,
            tipo:'zonasDelPaciente'
          },
          beforeSend:function () {
            $('#zonasPaciente').html('Cargando Zonas');
          },
          success:function (res) {
            var e = eval(res);
            console.log('Zonas del paciente');
            console.log(e);
            var render = '<label for="zona">Zonas del paciente</label>'+
              '<select name="zona" id="zona" class="form-control">';
            for (var i = 0; i < e.length; i++) {
              render += '<option value="'+e[i].zona+'">'+e[i].NombreZona+'</option>';
            }
            render += '</select>';

            $('#zonasPaciente').html(render);
          },
          error:function () {
            Paciente.zonasDelPaciente(paciente);
          }
        });*/

        cargarPHP('inc/zonasSelect.php?paciente='+paciente,'#zonasPaciente');

        /*$.getJSON('fun/data.php?tipo=zonasDelPaciente&idpaciente='+paciente, function (res) {


        }).error(function (res) {
          console.log('Error zonas paciente');
          console.log(res.responseText)
        })*/
    },
    /**
     * Filtrar los pacientes por fecha
     */
    filtrarPorFecha:   function () {
        var inicio=$('#inicio').val();
        var fin   =$('#fin').val();
        cargarPHP('sec/listarPacientes.php?filtroFecha=1&inicio='+inicio+'&fin='+fin,'.data');
    },
    filtrarPorSesiones:function () {
        var inicio=$('#inicio').val();
        var fin   =$('#fin').val();
        cargarPHP('sec/listarPacientes.php?filtroSesiones=1','.data');
    }
};

/**
 * Ver o sacar las zonas
 * @param id
 */
function paraTurno(id) {
    var check  =$('#'+id).is(':checked');
    var zonaDiv=$('#zonas-div');

    if (check) {
        zonaDiv.css('display','block')
    } else {
        zonaDiv.css('display','none')
    }
}

/**
 * Acciones para usuarios
 * @type {{deleteUser: Users.deleteUser, insertUser: Users.insertUser, editUser: Users.editUser}}
 */
var Users={
    /**
     * Borrar usuario
     * @param id
     */
    deleteUser:function (id) {
        if (confirm('Desea borrar?')) {
            ajaxInsert('fun/actions','deleteUser&id='+id,'','sec/listarUsuarios.php','.data');
        }
    },

    /**
     * insertar usuario
     */
    insertUser:function () {
        ajaxInsert('fun/actions','insertUser','add','sec/listarUsuarios.php','.data');
        $('textarea,:text').val('');
    },

    /**
     * Editar usuario
     */
    editUser:function () {
        ajaxInsert('fun/actions','editUser','edit');
    }
};

/**
 * Mails
 * @type {{edit: Mails.edit}}
 */
var Mails={

    edit:function (idMail,form) {

        var contenido=$('#'+form+' textarea').val();
        console.log(contenido);

        $.ajax({
            url:    'fun/actions.php',
            method: 'POST',
            data:   {
                tipo:  'editMail',
                id:    idMail,
                cuerpo:contenido
            },
            success:function (res) {
                console.log(res);
                alert(res);
            }
        });
        //ajaxInsert('fun/actions','editMail&id='+idMail,'edit-'+idMail);
    }
};

var Rec={
    getRec:function () {

        var valores={
            inicio:  $('#inicio').val(),
            fin:     $('#fin').val(),
            sucursal:$('#suc').val(),
            consul:  $('#consul').val(),
            tipo:    'recaudacion'
        };

        $.ajax({
            url:       'fun/actions.php',
            method:    'post',
            data:      valores,
            beforeSend:function () {

            },
            success:   function (res) {
                console.log(res);
                if (res == '') {
                    res=0;
                }
                $('#result').html('$'+res);
            },
            error:     function (res) {

            }
        })
    }
};

var Recordatorios={
    /**
     * Enviar todos
     * @param tiempo
     */
    send:function (tiempo) {

        /*
         $.ajax({
         url: 'fun/actions.php',
         method: 'GET',
         data: {
         tipo: 'sendRecordatorios'
         },
         success: function (res) {
         console.log('Enviados-->' + res);
         },
         error: function (res) {
         console.log(res.responseText);
         }
         });

         setInterval(function () {
         console.log('Enviando recordatorios');

         $.ajax({
         url: 'fun/actions.php',
         method: 'GET',
         data: {
         tipo: 'sendRecordatorios'
         },
         success: function (res) {
         console.log('Enviados-->' + res);
         },
         error: function (res) {
         console.log(res.responseText);
         }
         })
         }, 10000);
         */
        console.log('No envio nada');

    },

    /**
     * enviar recordatorio unico
     * @param id
     * @returns {boolean}
     */
    enviarRec:function (id) {
        if (confirm('¿Desea enviar el recordatorio ahora?')) {
            var fecha=document.getElementById('fecha').value;
            ajaxInsert('fun/actions','envioRecUnico&id='+id,'','sec/listarRecordatorios.php?fecha='+fecha,'.data',true,true);
        } else {
            return false;
        }
    },

    /**
     * Cargar
     */
    load:function () {
        var fecha=document.getElementById('fecha').value;
        cargarPHP('sec/listarRecordatorios.php?fecha='+fecha,'.data');
    },

    /**
     * Cambiar la fecha del recordatorio
     * @param idRec
     * @param fecha
     */
    cambiarFecha:function (idRec,fecha) {
        console.log('cambiar a '+fecha);
        var datos={
            id:   idRec,
            fecha:fecha,
            tipo: 'cambiarFecha'
        };
        $.ajax({
            url:    'fun/actions.php',
            method: 'POST',
            data:   datos,
            success:function (res) {
                console.log(res);
                cargarPHP('sec/listarRecordatorios.php?fecha='+$.trim(res),'.data');

                var fecha  =document.getElementById('fecha');
                fecha.value=res;
            }
        })
    },

    /**
     * Borrar un recordatorio del sistema
     * @param id
     * @param fecha
     * @param paciente
     */
    borrarRecordatorio:function (id,fecha,paciente) {
        if (confirm('Se borrara el recordatorio desea continuar?')) {
            ajaxInsert('fun/actions','borrarRecordatorio&id='+id,'','sec/listarRecordatorios.php?fecha='+fecha+'&id='+paciente,'.data');
        }
    },

    /**
     * Borrar los recordatorios del pacientes
     * @param id
     */
    borrarRecordatorioPaciente:function (id) {
        if (confirm('Se borrara el recordatorio desea continuar?')) {
            ajaxInsert('fun/actions','borrarRecordatorio&id='+id,'','','');
            window.location.href='';
        }
    },

    /**
     * Vista Previa del recordatorio
     * @param idRecordatorio
     */
    vistaPrevia:function (idRecordatorio) {
        var previa=$('.previa-recordatorio');
        cargarPHP('inc/previa.php?id='+idRecordatorio,previa);
        $('#previaRec').modal('show');
    },

    /**
     * Cambiar el estado de los recordatorios
     */
    cambiarEstado:function (id) {
        $.ajax({
            url:    'fun/actions.php',
            data:   {
                t:   'cambioEstadoRec',
                tipo:'cambioEstadoRec',
                id:  id
            },
            success:function (res) {
                console.log(res);
                alert(res);

                var fecha=document.getElementById('fecha').value;
                cargarPHP('sec/listarRecordatorios.php?fecha='+fecha,'.data');

            }
        });
    }
};


var Clean={
    cleanFecha:function () {
        $('.load').html('')
    }
};

/**
 * Backup
 * @type {{crear: Backup.crear}}
 */
var Backup={
    /**
     * Crear backup
     */
    crear:function () {
        setInterval(function () {
            $.ajax({
                url:    'fun/actions.php',
                method: 'GET',
                data:   {
                    t:   'forceBack',
                    tipo:'forceBack'
                },
                success:function (res) {
                    console.log(res);
                }
            })
        },60000);
    }
};

var Panel={

    loadData:function () {
        var panel=$('#panel');
        $.ajax({
            url:    'fun/actions.php',
            method: 'POST',
            data:   {
                t:   'panel',
                tipo:'panel'
            },
            success:function (res) {
                panel.html('Recordatorios sin enviar <b>'+$.trim(res)+'</b>');
                console.log(res);
            },
            error:  function (res) {
                console.log(res.responseText);
            }
        })
    },

    /**
     * Check intervalos
     */
    check:function () {
        Panel.loadData();
        setInterval(function () {
            Panel.loadData();
        },5000)
    }
};


var Consultas={
    delete:function () {
        if (confirm('Borrar todas las consultas?')) {
            ajaxInsert('fun/actions','deleteConsultas');
            setTimeout(function () {
                window.location.href='';
            },2500);
        } else {
            return false;
        }
    }
};

/**
 * Filtro de tabla
 * @type {{FiltroTabla: Filter.FiltroTabla}}
 */
var Filter={
    /**
     * Filtrar en tabla
     * @param valor
     * @constructor
     */
    FiltroTabla:function (valor) {
        var table=$('table');
        var value=valor;
        table.find('tr').each(function (index,row) {
            var allCells=$(row).find('td');
            if (allCells.length>0) {
                var found=false;
                allCells.each(function (index,td) {
                    var regExp=new RegExp(value,'i');
                    if (regExp.test($(td).text())) {
                        found=true;
                        return false;
                    }
                });
                if (found === true) $(row).show();
                else $(row).hide();
            }
        });
    }
};



